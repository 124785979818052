import { get, set, join, forEach, toString } from 'lodash';
import Cookies from 'js-cookie';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { SubmissionError } from 'redux-form';
import { createBrowserHistory } from 'history';
import { signOut } from '@services/session';

// Config
import { API_URI, MESSAGES_MAP } from '@config';

// Create history
export const HISTORY = createBrowserHistory({
  basename: '/',
  forceRefresh: false
});

// Server errors hanndler
export const SERVER_ERRORS_HANDLER = (error?: any, exception?: Function) => {
  const messageErrors = get(error, 'message');
  const validationErrors = get(error, 'graphQLErrors.0.extensions.validation');
  const submission = {
    _error: messageErrors
  };
  forEach(validationErrors, (value: string, key?: any) => {
    const newKey = key.replace('input.', '').replace(/(email|phone)/, 'login');
    const message = join(value, '\n');
    set(submission, newKey, get(MESSAGES_MAP, `${message}`, message));
  });
  if (validationErrors) {
    throw new SubmissionError(submission);
  } else {
    exception && exception();
  }
};

// Create apollo client
export const CLIENT: ApolloClient<InMemoryCache> = new ApolloClient({
  cache: new InMemoryCache(),
  onError: ({ networkError, graphQLErrors, operation, forward, ...props }) => {
    const SIGN_IN_URL = '/users/sign_in';
    const ERROR_URL = '/errors/network';

    switch (true) {
      case window.location.pathname !== SIGN_IN_URL &&
        (['You have to log in to run this query!'].indexOf(
          get(graphQLErrors, '[0].message')
        ) >= 0 ||
          [401].indexOf(get(networkError, 'statusCode')) >= 0 ||
          ['Failed to fetch'].indexOf(get(networkError, 'message')) >= 0):
        // window.location = SIGN_IN_URL;
        signOut();
        break;
      case window.location.pathname !== ERROR_URL &&
        [404, 500].indexOf(get(networkError, 'statusCode')) >= 0:
        window.location = ERROR_URL;
        break;
      default:
        break;
    }
  },
  uri: API_URI,
  credentials: 'include',
  // credentials: 'same-origin',
  // request: (operation: any) => {
  //   const token = 'eyJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI3MDY5NDZmNy01YmEwLTQxNjgtOTYwNS0yY2FlZGI2OWMyNTMiLCJzdWIiOiIyMzUxIiwic2NwIjoidXNlciIsImF1ZCI6bnVsbCwiaWF0IjoxNjQ3ODQ2Nzg3LCJleHAiOjE2NDc4NTAzODd9.dWnkyugOxQF9FS_9UcOsRjiXlQcwpfUXj0okNwCsVrc';
  //   try {
  //     operation.setContext({
  //       headers: {
  //         authorization: token ? `Bearer ${token}` : '',
  //       },
  //     });
  //   } catch (error) {
  //   }
  // },
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all'
    },
    query: {
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
});
